import PropTypes from 'prop-types';

export const MetricsShape = PropTypes.shape({
  pageLoadStarted: PropTypes.func.isRequired,
  pageLoadSuccess: PropTypes.func.isRequired,
  pageLoadFailure: PropTypes.func.isRequired,
  searchLoaded: PropTypes.func.isRequired,
  searchSubmitted: PropTypes.func.isRequired,
  searchClicked: PropTypes.func.isRequired,
  searchValidationFailed: PropTypes.func.isRequired,
  searchCycleStarted: PropTypes.func.isRequired,
  userSearched: PropTypes.func.isRequired,
  searchSuccess: PropTypes.func.isRequired,
  searchEmpty: PropTypes.func.isRequired,
  searchFailure: PropTypes.func.isRequired,
  searchResultSelected: PropTypes.func.isRequired,
  pricesStarted: PropTypes.func.isRequired,
  pricesSuccess: PropTypes.func.isRequired,
  pricesEmpty: PropTypes.func.isRequired,
  pricesFailure: PropTypes.func.isRequired,
  dbookPriceSelected: PropTypes.func.isRequired,
  similarHotelsSuccess: PropTypes.func.isRequired,
  similarHotelsEmpty: PropTypes.func.isRequired,
  similarHotelsFailure: PropTypes.func.isRequired,
  hotelDetailsPriceLoaded: PropTypes.func.isRequired,
  detailsMainCTAClicked: PropTypes.func.isRequired,
  detailsOtherMetaPriceClicked: PropTypes.func.isRequired,
  bookingStartFailure: PropTypes.func.isRequired,
  bookingFlowTransition: PropTypes.func.isRequired,
  stathamBookingState: PropTypes.func.isRequired,
  stathamBookingTiming: PropTypes.func.isRequired,
  preReservationAvailabilityCheck: PropTypes.func.isRequired,
  finalScreenView: PropTypes.func.isRequired,
  componentLoadFailure: PropTypes.func.isRequired,
  fastSearchSuccess: PropTypes.func.isRequired,
});

export const BackendGatewayShape = PropTypes.shape({
  startSearchUpdates: PropTypes.func.isRequired,
  startMapUpdates: PropTypes.func.isRequired,
  startPricesUpdates: PropTypes.func.isRequired,
  trimUrl: PropTypes.func.isRequired,
});

export const StayShape = PropTypes.shape({
  checkIn: PropTypes.instanceOf(Date),
  checkOut: PropTypes.instanceOf(Date),
  numberOfAdults: PropTypes.number,
  numberOfRooms: PropTypes.number,
  childrenAges: PropTypes.arrayOf(PropTypes.string),
});

export const DestinationShape = PropTypes.shape({
  entityId: PropTypes.string,
  entity: PropTypes.string.isRequired,
  city: PropTypes.string,
  adm1: PropTypes.string,
  adm2: PropTypes.string,
  nation: PropTypes.string,
  type: PropTypes.string,
});

const CugOverrideShape = PropTypes.arrayOf(
  PropTypes.oneOf(['flight_booked', 'flight_customer']),
);

const DeviceShape = PropTypes.shape({
  isTablet: PropTypes.bool,
  isMobilePhone: PropTypes.bool,
  isBrowser: PropTypes.bool,
});

const UserPreferencesShape = PropTypes.shape({
  utid: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isNewUser: PropTypes.bool,
  userPreferencesId: PropTypes.string,
});

export const UserContextShape = PropTypes.shape({
  bellboyServedBy: PropTypes.string,
  bellboySortingVersion: PropTypes.string,
  cugOverride: CugOverrideShape,
  device: DeviceShape,
  flightsCugEnabled: PropTypes.bool,
  ipAddress: PropTypes.string,
  locale: PropTypes.string,
  searchResultCacheEnable: PropTypes.bool,
  searchCycleId: PropTypes.number,
  userPreferences: UserPreferencesShape,
  pageType: PropTypes.string,
});

const SinglePriceDataShape = PropTypes.shape({
  direction: PropTypes.string,
  median: PropTypes.number,
});

export const CalendarPriceDataShape = PropTypes.objectOf(SinglePriceDataShape);

export const FeatureTestsShape = PropTypes.shape({
  variant: PropTypes.func.isRequired,
});
