import type { ComponentType } from 'react';

import displayName from '../../components-search-controls-adjacent/display-name';
import { FeatureTestsShape } from '../../shapes';

import type { FeatureTestsType } from '../types';
import type { Optional } from 'common-types/types/components';

interface ContextProps {
  featureTests: FeatureTestsType;
}

const ContextTypes = {
  featureTests: FeatureTestsShape,
};
const withFeatureTests = <P extends ContextProps>(
  Component: ComponentType<P>,
) => {
  const component = (
    props: Optional<P, 'featureTests'>,
    { featureTests }: ContextProps,
  ) => (
    // @ts-ignore we expect that featureTests can be overwritten in the unit tests
    <Component featureTests={featureTests} {...(props as P)} />
  );

  component.contextTypes = ContextTypes;

  component.displayName = displayName(Component);

  return component;
};

export default withFeatureTests;
