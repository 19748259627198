import Localisation, {
  currency as currencyFeatures,
  number as numberFeatures,
  date as dateFeatures,
} from 'saddlebag-localisation';

import { formatDate, parseDate } from '../../date-utils';

import { DATE_FORMAT } from './date-formats';

import type { Culture } from 'common-types/types/Context';
import type { DayNumberOfWeek } from 'common-types/types/components';

const LocalisationService = (
  cldrInfo: Record<string, any>,
  culture: Culture,
  logger?: { error: Function },
) => {
  try {
    Localisation.enhance(currencyFeatures, numberFeatures, dateFeatures);
    const saddlebagLocalisation = Localisation(cldrInfo);

    return {
      formatCurrency: (value: string | number, format = 'c0') =>
        saddlebagLocalisation.formatCurrency(value, format),
      formatCurrencyFull: (value: string | number, format = 'c2') =>
        saddlebagLocalisation.formatCurrencyFull(value, format),
      getCurrencySymbol: () => saddlebagLocalisation.getCurrencySymbol(),
      formatNumber: (value: string | number, decimals = 1) =>
        saddlebagLocalisation.formatNumber(value, decimals),
      formatAtLeast: (value: string) =>
        saddlebagLocalisation.formatAtLeast(value),
      formatDate: (date: string | Date, format = 'short') => {
        const definitelyDate = parseDate(date);
        if (
          format === DATE_FORMAT.NON_LOCALISED_SHORT ||
          format === DATE_FORMAT.NON_LOCALISED_YEAR_MONTH
        ) {
          return saddlebagLocalisation.formatDateNonlocalised(
            definitelyDate,
            format,
          );
        }
        return saddlebagLocalisation.formatDate(definitelyDate, format);
      },
      formatTime: (time: string | Date, format = 'short') => {
        const definitelyTime = parseDate(time);
        return saddlebagLocalisation.formatTime(definitelyTime, format);
      },
      formatDateTime: (datetime: string | Date, format = 'short') => {
        const definitelyDateTime = parseDate(datetime);
        return saddlebagLocalisation.formatDateTime(definitelyDateTime, format);
      },
      getFirstDay: (): DayNumberOfWeek => saddlebagLocalisation.getFirstDay(),
      getDaysOfWeek: () => saddlebagLocalisation.getDaysOfWeek(),
      getMonthName: (date: string | number | Date, format = 'wide') =>
        saddlebagLocalisation.getMonthName(date, format),
      monthNames: (format = 'wide') => saddlebagLocalisation.monthNames(format),
      dayNames: (format = 'wide') => saddlebagLocalisation.dayNames(format),
      dayNamesUnordered: (format = 'wide') =>
        saddlebagLocalisation.dayNamesUnordered(format),
      getCardExpiryPlaceholder: () =>
        saddlebagLocalisation.getCardExpiryPlaceholder(),
      getDatePlaceholder: () => saddlebagLocalisation.getDatePlaceholder(),
      parseDate: (date: string) => parseDate(date),
    };
  } catch (err: any) {
    const msg = `Caught an exception when saddlebag localisation initialization: ${err}\n${err.stack}`;
    logger?.error(msg);

    // eslint-disable-next-line global-require
    const currencySymbols = require('./currency-symbols').default;
    const { currency } = culture;
    const symbol = currencySymbols[currency];
    if (!symbol) {
      throw new Error(`Currency ${currency} cannot be fallback`);
    }

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
    ];
    const daysOfWeek = [
      {
        name: 'Monday',
        nameAbbr: 'Mon',
        nameShort: 'Mo',
        index: 1,
        cldrKey: 'mon',
        isWeekend: false,
      },
      {
        name: 'Tuesday',
        nameAbbr: 'Tue',
        nameShort: 'Tu',
        index: 2,
        cldrKey: 'tue',
        isWeekend: false,
      },
      {
        name: 'Wednesday',
        nameAbbr: 'Wed',
        nameShort: 'We',
        index: 3,
        cldrKey: 'wed',
        isWeekend: false,
      },
      {
        name: 'Thursday',
        nameAbbr: 'Thu',
        nameShort: 'Th',
        index: 4,
        cldrKey: 'thu',
        isWeekend: false,
      },
      {
        name: 'Friday',
        nameAbbr: 'Fri',
        nameShort: 'Fr',
        index: 5,
        cldrKey: 'fri',
        isWeekend: false,
      },
      {
        name: 'Saturday',
        nameAbbr: 'Sat',
        nameShort: 'Sa',
        index: 6,
        cldrKey: 'sat',
        isWeekend: true,
      },
      {
        name: 'Sunday',
        nameAbbr: 'Sun',
        nameShort: 'Su',
        index: 0,
        cldrKey: 'sun',
        isWeekend: true,
      },
    ];
    const formatNumber = (value: string | number, decimals = 1) => {
      const digits = Number(value).toFixed(decimals).split('.');
      const decimal = digits[1];
      const integerChar = digits[0].split('').reverse();
      const integer = integerChar.reduce((result, char, index) => {
        if (index % 3 === 0 && index !== 0) {
          return `${char},${result}`;
        }
        return `${char}${result}`;
      }, '');
      return decimal ? `${integer}.${decimal}` : String(integer);
    };

    return {
      formatCurrency: (value: string | number, format = 'c0') => {
        const digits = Number(format.substring(1));
        return `${symbol}${formatNumber(Math.ceil(Number(value)), digits)}`;
      },
      formatCurrencyFull: (value: string | number, format = 'c2') => {
        const digits = Number(format.substring(1));
        return `${symbol}${formatNumber(value, digits)}`;
      },
      formatNumber,
      formatAtLeast: (value: string) => value,
      formatDate: (date: string | Date) => formatDate(date),
      formatTime: (time: string | Date) => time as string,
      formatDateTime: (datetime: string | Date) => datetime as string,
      getFirstDay: (): DayNumberOfWeek => 0,
      getDaysOfWeek: () => daysOfWeek,
      getMonthName: (month: string | number | Date, format = 'wide') =>
        monthNames[month as number],
      monthNames: () => monthNames,
      // Added to avoid test errors due to hotels-website migration
      dayNames: () => daysOfWeek,
      getCurrencySymbol: () => symbol,
      dayNamesUnordered: () => daysOfWeek,
      getDatePlaceholder: () => 'dd/mm/yyyy',
      getCardExpiryPlaceholder: () => 'mm/yy',
    };
  }
};

export default LocalisationService;
