import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';
// use date-fns/format for fallback when fail to get cldr from culture-service although it seldom happen
// eslint-disable-next-line skyscanner-dates/no-date-fns-format
import { format as dateFnsFormat } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';

export const parseDate = (dateStr: string | Date) => {
  if (typeof dateStr === 'string') {
    return parseISO(dateStr);
  }
  return dateStr;
};

// returns a machine-readable date ignoring locale
// this function should be only used for server side, use the one in i18n for client side
export const formatDate = (date: string | Date, format = 'yyyy-MM-dd') => {
  const definitelyDate = parseDate(date);
  return dateFnsFormat(definitelyDate, format);
};

export const differenceDaysLength = (
  fromDate: string | Date,
  toDate: string | Date,
) => {
  const length = differenceInCalendarDays(
    parseDate(fromDate),
    parseDate(toDate),
  );
  if (Number.isNaN(length)) {
    return 0;
  }
  return length;
};

export const stayLength = ({
  checkIn,
  checkOut,
}: {
  checkIn: string | Date;
  checkOut: string | Date;
}) => differenceDaysLength(checkOut, checkIn);

export const wrapDate = (date: string | Date) => {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return date;
};
