import isArray from 'lodash/isArray';
import reactReplacePlaceholder from 'react-replace-placeholder';

import makePluralKey, {
  DEFAULT_MAX_VARIANTS,
  DEFAULT_PLURAL_SUFFIX,
} from './make-translations-plural-key';

const TranslationService = (translations: Record<string, any>) => {
  const translate = (
    key: string,
    replacements: Record<string, any> = {},
    startTag = '{',
    endTag = '}',
    toString = false,
  ): string | Array<string | JSX.Element> | null => {
    if (toString) {
      const originResult = translate(
        key,
        replacements,
        startTag,
        endTag,
        false,
      );
      if (isArray(originResult)) {
        return originResult.join('');
      }
      if (typeof originResult === 'string') {
        return originResult;
      }
      return '';
    }
    const message = translations[key];
    if (!message) {
      // eslint-disable-next-line no-console
      console.error(`Missing translation for key "${key}"`);
      return null;
    }
    if (!replacements || Object.keys(replacements).length === 0) {
      return message;
    }

    return reactReplacePlaceholder(message, replacements, startTag, endTag);
  };

  const translatePlural = (
    key: string,
    cardinality: number,
    replacementKey: string,
    extraReplacements: Record<string, any> = {},
    maxVariants = DEFAULT_MAX_VARIANTS,
    extra = DEFAULT_PLURAL_SUFFIX,
    startTag?: string,
    endTag?: string,
  ) => {
    const pluralKey = makePluralKey(key, cardinality, maxVariants, extra);

    const replacements = { ...(extraReplacements || {}) };
    if (cardinality > maxVariants) {
      replacements[replacementKey] = cardinality;
    }
    return translate(pluralKey, replacements, startTag, endTag);
  };

  return {
    translate,
    translatePlural,
  };
};

export default TranslationService;
