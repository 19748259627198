// eslint-disable-next-line import/prefer-default-export
export const DATE_FORMAT = {
  FULL: 'full', // Wednesday, 7 March 2018
  LONG: 'long', // 7 March 2018
  MEDIUM: 'medium', // 7 Mar 2018
  SHORT: 'short', // 07/03/2018
  YEAR_MONTH: 'yearMonth', // March 2018
  YEAR_MONTH_ABBR: 'yearMonthAbbr', // Mar 2018
  MONTH_DAY: 'monthDay', // 7 March
  FULL_ABBR: 'fullAbbr', // Wed, 7 Mar 2018
  MONTH_DAY_ABBR: 'monthDayAbbr', // Wed 7 Mar
  MONTH_DAY_NO_WEEK_ABBR: 'monthDayNoWeekAbbr', // 7 Mar
  NON_LOCALISED_SHORT: 'yyyy-MM-dd',
  NON_LOCALISED_YEAR_MONTH: 'yyyy-MM',
};
