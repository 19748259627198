export const DEFAULT_MAX_VARIANTS = 7;
export const DEFAULT_PLURAL_SUFFIX = 'Other';

const makePluralKey = (
  key: string,
  cardinality: number = 0,
  maxVariants = DEFAULT_MAX_VARIANTS,
  extra = DEFAULT_PLURAL_SUFFIX,
) => {
  let plural = key;

  if (cardinality > maxVariants) {
    plural = `${key}${extra}`;
  } else if (cardinality > 0) {
    plural = `${key}${cardinality}`;
  }

  return plural;
};

export default makePluralKey;
