import { GLOBAL } from 'saddlebag-browser';
import { logError, logWarn, logOperationalEvent } from 'saddlebag-logger';

// import namespace from '../namespace';

const $window = GLOBAL.getWindow();
// eslint-disable-next-line no-underscore-dangle
const { logging = {} } = $window.__internal || {};

const namespace = 'hotels-website-in-falcon';

const wrapError = (msg: any, error: any) => {
  if (error instanceof Error) {
    return error;
  }
  return new Error(msg);
};

// NOTE: be aware that new relic only support number/string types
// See here: https://github.skyscannertools.net/mshell-node/mshell-node-rum/blob/master/src/snippets/performanceObserver.js#L12
export default {
  // 1. For events to viﬁew in dashboards or alert
  // 2. For non-warning or non-error logging
  event: (eventName: any, data?: any) => {
    logOperationalEvent({
      ...data,
      ...logging,
      component: namespace,
      eventName,
    });
  },

  warn: (msg: any, data?: any) => {
    logWarn({
      ...data,
      ...logging,
      component: namespace,
      message: msg,
    });
  },

  error: (msg: any, data: any, error?: any) => {
    const errorObject = wrapError(msg, error);

    logError(errorObject, {
      ...data,
      ...logging,
      component: namespace,
      message: msg,
    });
  },
};
